<template lang="pug">
b-modal(id="requireConsultantTimeModal", :ref="requireConsultantTimeModal", :size="'lg'",@close="close",no-close-on-backdrop='', centered)
  template(#modal-header="{ close }")
    img.normal-close-btn(:src="closeIconUrl" @click="close()")
  template(#default)
    h2.normal-modal-title {{ $t('requireConsultantTime') }}

    //- 步驟列
    .step-bar
      .step(v-for="(item, index) in step.enum", :class="{active: step.active == index + 1}")
        .step-circle {{ index+ 1}}
        p {{ item }}

    //- 訂課步驟 1
    transition(name="fade", mode="out-in")
      section(v-if="step.active == 1", key="1")

        b-container
          b-row.mt-3
            b-col.modal-label(cols="3") {{$t('designatedConsultant')}}
            b-col.mt-1.modal-text(cols="9") {{ consultantInfo.name }}
          b-row.mt-3
            b-col.modal-label(cols="3") {{$t('designatedDate')}}
            b-col(cols="9")
              //- 需做防呆 取得系統時間
              select-date(:date.sync="requireParams.date" :disabledDate="disabledDate" :placeholder="$t('selectDate')" @changeDate="changeDate")
          b-row.mt-3(v-if="requireParams.date")
            b-col.modal-label(cols="3") {{$t('designatedTime')}}
            b-col(cols="9")
              b-row
                b-col(cols="6")
                  b-form-select.modal-select(v-model="requireParams.timeSection" :options="timeSectionOption" @change="changeTimeSection")
                b-col(cols="6" v-if="requireParams.timeSection")
                  b-form-select.modal-select(v-model="requireParams.time" :options="requireTimeOptions")
          b-row.mt-3
            //- TODO 指定教材加入元件 By Asa
            b-col.modal-label(cols="3") {{$t('designatedMaterial')}}
            b-col(cols="9")
              b-row.mt-2
                b-col(cols="2")
                  b-form-checkbox(
                    v-model="requireParams.isUploadMaterial"
                    :value="1"
                    :unchecked-value="0"
                    @change="changeUploadMaterialCheckbox"
                  )
                b-col(cols="10")
                  b-form-file(
                    v-if="requireParams.isUploadMaterial === 1"
                    v-model="requireParams.materialFile"
                    :state="Boolean(requireParams.materialFile)"
                    :placeholder="$t('uploadYourFile')"
                  )
                  p.mt-1.text-success(v-if="requireParams.materialFile") 已選擇檔案： {{requireParams.materialFile.name}}
              b-row.mt-2
                b-col
                  b-alert(v-if="requireParams.isUploadMaterial" show variant="warning")
                    span(v-html="$t('designatedMaterialNotice')")

      //- 訂課步驟 2
      section(v-if="step.active == 2", key="2")
        b-list-group
          b-list-group-item 日期：{{ formatDate(requireParams.date) }}
          b-list-group-item 時段：{{ findOption(timeSectionOption, 'value', requireParams.timeSection, 'text') }}
          b-list-group-item 時間：{{ formatTime(requireParams.time) }}
          b-list-group-item 指定顧問：{{ consultantInfo.name }}
          b-list-group-item(v-if="requireParams.isUploadMaterial") 指定自備教材： {{requireParams.materialFile ? requireParams.materialFile.name: '--'}}

        //-  for 舊合約使用者
        b-alert.mt-3(v-if="!customizedClassroomRule.new_rule",variant="warning",show)
          p 提醒您 :
          ol
            li
              span.text-danger 特殊時段申請須消耗堂數 2 堂
              span ，可向顧問申請額外加開授課時段(需符合原訂WUWOW Jr開課時段，但顧問未開放授課之條件)，可同時上傳自備教材。
            li 須待顧問回覆同意後才算訂課成功，也才會扣除堂數。
            li
              span 3. 顧問將於課前36小時於系統回覆是否接受加開申請， 如未申請成功，則不需計算堂數。
              span.text-danger 如預約成功，則不得取消課程。
            li 若指定之顧問因不可抗因素無法授課，教務團隊將為你更換顧問，並即時通知您。

        //-  for 新合約使用者
        b-alert.mt-3(v-if="customizedClassroomRule.new_rule",variant="warning",show)
          p 提醒您 :
          ol
            li
              span.text-danger 特殊時段申請須消耗堂數 2 堂
              span ，可向顧問申請額外加開授課時段(需符合原訂WUWOW Jr開課時段，但顧問未開放授課之條件)，可同時上傳自備教材。
            li 須待顧問回覆同意後才算訂課成功，也才會扣除堂數。
            li
              span 顧問將於課前36小時於系統回覆是否接受加開申請， 如未申請成功，則不需計算堂數。
              span.text-danger 如預約成功，則不得取消課程。
            li 若指定之顧問因不可抗因素無法授課，教務團隊將為你更換顧問，並即時通知您。

        //- b-container
        //-   b-row.mt-5
        //-     div.remark
        //-       p(v-html="$t('appointrequireConsultantRemark')")
        //-     p.notice 【一般課程-指定顧問、客製化課程】如「有」指定顧問或上傳自備教材，課程開始24小時前登入個人學習系統取消課程，不扣堂數；如於課程開始前「24小時內」預約指定顧問，則不得取消。

  template(#modal-footer="{ hide }")
    b-button.mt-3(v-if="step.active == 1",variant="primary" block @click="step.active = 2" key="next",:disabled="!enableSubmit") 下一步 / 確認課程資訊

    //- 第二步才送出
    b-button.mt-3(v-if="step.active == 2",variant="primary" block @click="step.active = 1" key="previous") 上一步
    b-button.mt-3(v-if="step.active == 2",variant="success" block @click="submit()" key="success") 確認送出訂課

//-     //- 確認送出 modal
//-     b-modal(id="confirmModal",hide-footer, centered)
//-       h2 請確認是否送出訂課申請
//-       .modal-footer
//-         b-button(variant="danger", @click="$bvModal.hide('confirmModal');$bvModal.hide('requireConsultantTimeModal')") 取消
//-         b-button(variant="success", @click="$bvModal.hide('confirmModal');$bvModal.hide('requireConsultantTimeModal');submit()") 送出
</template>

<script>
// 防呆 1.時間選擇 2.指定教材需要上教材
import moment from 'moment';
import { forEach, get } from 'lodash';
import i18n from '@/views/dojo/bookClass/requireConsultant/schedule/bookClassModal/lang';
import normalModal from '@/components/modal/normal/index.vue';
import selectDate from '@/components/filter/selectDate/index.vue';
import lioshutanApi from '@/api';
import { alertSuccessMessage, alertFailedMessage, alertWarningMessage } from '@/utils/sweetAlert.js';
import platformConstants from '@/constants/platform';
import classroomConstants from '@/constants/classroom';
import closeIcon from '@/components/modal/normal/image/close.png';

const currentPalaform = platformConstants.WUWOW_JUNIOR;
const TIME_SECTION = { morning: 'morning', afternoon: 'afternoon', evening: 'evening' };
export default {
  name: 'RequireConsultantTimeModal',

  components: {
    normalModal,
    selectDate,
  },

  i18n,

  props: {
    consultantInfo: {
      type: Object,
      default() {
        return {};
      },
    },
    customizedClassroomRule: {
      type: Object,
      default() {
        return {};
      },
    },
  },

  data(){
    return {
      // 訂課步驟
      step: {
        active: 1,
        enum: ['選擇課程設定', '再次確認課程'],
      },

      // 圖示
      closeIconUrl: closeIcon,

      requireParams: {
        date: '',
        time: '',
        isUploadMaterial: 0,
        materialFile: null,
        timeSection: '',
      },
      currentAllTimeOptions: {},
      timeSectionOption: [
        { value: '', text: this.$t('selectTimeSection') },
        { value: TIME_SECTION.morning, text: this.$t(TIME_SECTION.morning) },
        { value: TIME_SECTION.afternoon, text: this.$t(TIME_SECTION.afternoon) },
        { value: TIME_SECTION.evening, text: this.$t(TIME_SECTION.evening) },
      ],

      requireTimeOptions: [],
    };
  },

  computed: {
    enableSubmit() {
      // 規則為檢查自備教材
      let rule = true;
      if (!this.requireParams.date || !this.requireParams.timeSection || !this.requireParams.time) {
        rule = false;
      }
      if (this.requireParams.isUploadMaterial && !this.requireParams.materialFile) {
        rule = false;
      }
      return rule;
    },
  },

  methods: {
    async submit(){
      const params = this.getRequireParamsFormData(this.requireParams, this.consultantInfo.id);
      try {
        const result = await lioshutanApi.bookingClass.requireConsultantClass(params);
        if (result.data.data.create[0].status){
          alertSuccessMessage(this.$t('sendSuccess'));
          this.$bvModal.hide('requireConsultantTimeModal');
          this.close();
        } else {
          alertFailedMessage(result.data.data.create[0].message);
        }
      } catch (error) {
        console.log(error.response);
        if (error.response.data.data.create[0].status === 2) {
          alertWarningMessage('溫馨提醒', '此時段已有預約課程，請更改申請詢問其他課程時段喔，謝謝您');
        } else {
          alertFailedMessage(this.$t('applyDataFaild'));
        }
      }
      // NOTE:訂課步驟 2 會顯示訂課資訊，關閉後再清空設定
      // this.resetParams();
    },

    async close(){
      this.resetParams();
      this.step.active = 1;
    },

    getRequireParamsFormData(requireParams, hbConsultantId){
      const formData = new FormData();
      formData.append('hbConsultantId', hbConsultantId);
      formData.append('askHbConsultantOption[0][classTime]', `${requireParams.date} ${requireParams.time}:00`);
      formData.append('askHbConsultantOption[0][isUploadMaterial]', requireParams.isUploadMaterial);
      formData.append('askHbConsultantOption[0][attachment]', requireParams.materialFile || '');
      formData.append('askHbConsultantOption[0][class_type]', classroomConstants.BOOK_CLASS.CLASS_TYPE_SPECIAL);
      return formData;
    },

    async changeDate(){
      if (this.requireParams.date !== null){
        this.getSpecificTimeSlot(this.requireParams.date);
      } else {
        this.currentAllTimeOptions = {};
      }
    },

    // 取得特定日期可選時段與時間
    async getSpecificTimeSlot(date){
      this.timeSectionOption = [{ text: this.$t('selectTimeSection'), value: null }];
      this.currentAllTimeOptions = {};
      const timeslots = await this.getClassTimeslots(currentPalaform, date);
      forEach(timeslots, (value, key) => {
        this.getTimeSection(key);
        const timeLists = value.map((timeInfo) => {
          return timeInfo.time;
        });
        this.currentAllTimeOptions[key] = timeLists;
      });
    },

    getTimeSection(timeSection){
      switch (timeSection){
        case TIME_SECTION.morning:
          this.timeSectionOption.push({ text: this.$t(TIME_SECTION.morning), value: timeSection });
          break;
        case TIME_SECTION.afternoon:
          this.timeSectionOption.push({ text: this.$t(TIME_SECTION.afternoon), value: timeSection });
          break;
        case TIME_SECTION.evening:
          this.timeSectionOption.push({ text: this.$t(TIME_SECTION.evening), value: timeSection });
          break;
        default:
          break;
      }
    },

    async getClassTimeslots(platform = currentPalaform, date){
      const params = {
        days: [date],
        platform: platform,
        splitSection: true, // 時間照區段切分(morngin)
      };
      const result = await lioshutanApi.bookingClass.getClassTimeslots(params);
      return result.data.data.timeslots[date];
    },

    changeTimeSection(){
      this.requireTimeOptions = [];
      this.requireTimeOptions = this.currentAllTimeOptions[this.requireParams.timeSection];
    },

    changeUploadMaterialCheckbox(value){
      if (!value){
        this.requireParams.materialFile = null;
      }
    },

    resetParams(){
      this.requireParams = {
        date: '',
        time: '',
        isUploadMaterial: 0,
        materialFile: null,
        timeSection: '',
      };
      this.currentAllTimeOptions = {};
      this.requireTimeOptions = [];
    },

    disabledDate(date){
      // console.log(date);
      // TODO 需要接系統時間
      const today = new Date();
      // 員工旅遊日期
      const trailCompany = ['2023-08-02', '2023-08-03', '2023-08-04'];
      if (trailCompany.includes(moment(date).format('YYYY-MM-DD'))) {
        return true;
      }
      return date < new Date(today.getTime() + 2 * 24 * 3600 * 1000) || date > new Date(today.getTime() + 30 * 24 * 3600 * 1000);
    },

    // 尋找選項
    findOption(sourceData, path, value, targetProp) {
      if (!value) {
        return '--';
      }
      const target = sourceData.find((item) => {
        // lodash get
        const itemProp = get(item, path);
        return itemProp === value;
      });

      if (targetProp) {
        return get(target, targetProp);
      }
      return target;
    },

    timeAlert() {
      const nextDay = moment().add(1, 'day');
      const bookingAt = moment(`${this.requireParams.date} ${this.requireParams.time}:00`);
      console.warn('isBefore', moment(bookingAt).isBefore(nextDay));

      // 判斷定課時間，是否於一天之內
      switch (moment(bookingAt).isBefore(nextDay)) {
        // 一天之內
        case true:
          return '此課程為24小時內之一對一指定課程，預約後不可取消喔！';
        // 一天之後
        default:
          return '此課程為24小時後之一對一指定課程，預約後如需取消，請於課前24小時前取消！';
      }
    },

    formatDate(date) {
      return moment(date).format('YYYY-MM-DD');
    },

    formatTime(time) {
      const today = moment().format('YYYY-MM-DD');
      return moment(`${today} ${time}`).format('HH:mm:ss');
    },
  },
};
</script>

<style lang="scss">
@import '@/styles/transition';
</style>

<style lang="scss">
.modal-label{
  top: 10px;
}
.modal-text{
  font-size: 20px;
  font-weight: 600;
}
.modal-select{
  height: 40px;
  border-radius: 10px;
}
:deep(.remark){
  color: red;
  margin-bottom: 3px;
  font-size: 13px;
  text-align: left;
  line-height: 16px;
}

// 修改 b-modal 排版
.modal-footer{
  flex-wrap: nowrap;
}

.time-alert{
  font-size: 18px;
}

</style>
