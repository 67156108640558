<template lang="pug">
  date-picker(
    v-model="thisDate"
    :placeholder="placeholder"
    :type="option.typeOfDate"
    :format="option.format"
    :value-type="option.returnValueFollowFormat"
    @change="changeDate()"
    :disabled-date="disabledDate"
  )
</template>

<script>
import datePicker from 'vue2-datepicker';
import 'vue2-datepicker/index.css';
export default {
  name: 'DatePicker',

  components: {
    datePicker,
  },

  props: {
    date: {
      type: String,
      default() {
        return '';
      },
    },
    placeholder: {
      type: String,
      default() {
        return 'Select Date';
      },
    },
    disabledDate: {
      type: Function,
      default() {
        () => {
          return '';
        };
      },
    },
  },

  data() {
    return {
      option: {
        format: 'YYYY-MM-DD',
        typeOfDate: 'date',
        returnValueFollowFormat: 'format',
      },
    };
  },

  computed: {
    thisDate: {
      get() {
        return this.date;
      },
      set(value) {
        this.$emit('update:date', value);
      },
    },
  },

  methods: {
    changeDate() {
      this.$emit('changeDate');
    },
  },
};
</script>

<style>
/* datepicker component */
.mx-datepicker {
  width: 100%;
}
.mx-datepicker > .mx-input-wrapper > .mx-input {
  height: 40px;
  border-radius: 10px;
}
</style>
