<template lang="pug">
b-modal(id="bookConsultantTimeModal", :ref="bookConsultantTimeModal", :size="'lg'", @close="close",no-close-on-backdrop='', centered)
  template(#modal-header="{ close }")
    img.normal-close-btn(:src="closeIconUrl" @click="close()")
  template(#default)
    h2.normal-modal-title {{ $t('normalBooking') }}

    //- 步驟列
    .step-bar
      .step(v-for="(item, index) in step.enum", :class="{active: step.active == index + 1}")
        .step-circle {{ index+ 1}}
        p {{ item }}

    //- 訂課步驟 1
    transition(name="fade", mode="out-in")
      section(v-if="step.active == 1", key="1")

        b-container
          b-row.mt-3
            b-col.modal-label(cols="3") {{$t('designatedConsultant')}}
            b-col.mt-1.modal-text(cols="9") {{ consultantInfo.name }}
          b-row.mt-3
            b-col.modal-label(cols="3") {{$t('designatedTime')}}
            b-col.mt-1.modal-text(cols="9") {{ bookingTime.time }}
          hr
          b-row.mt-3(v-if="newRule")
            //- TODO 指定教材加入元件 By Asa
            b-col.modal-label(cols="3") {{$t('designatedMaterial')}}
            b-col(cols="9")
              b-row.mt-2
                b-col(cols="2")
                  b-form-checkbox(
                    v-model="requireParams.isUploadMaterial"
                    :value="true"
                    :unchecked-value="false"
                  )
                b-col(cols="10")
                  b-form-file(
                    v-if="requireParams.isUploadMaterial"
                    v-model="requireParams.materialFile"
                    :state="Boolean(requireParams.materialFile)"
                    :placeholder="$t('uploadYourFile')"
                  )
                  p.mt-1.text-success(v-if="requireParams.materialFile") 已選擇檔案： {{requireParams.materialFile.name}}
              b-row.mt-2
                b-col
                  b-alert(v-if="requireParams.isUploadMaterial" show variant="warning")
                    span(v-html="$t('designatedMaterialNotice')")

      //- 訂課步驟 2
      section(v-if="step.active == 2", key="2")
        b-list-group
          b-list-group-item 日期：{{ formatDate(bookingTime.time) }}
          b-list-group-item 時間：{{ momentFormat(bookingTime.time, 'HH:mm:ss') }}
          b-list-group-item 指定顧問：{{consultantInfo.name}}
          b-list-group-item(v-if="requireParams.isUploadMaterial") 指定自備教材：{{requireParams.materialFile ? requireParams.materialFile.name: '--'}}

        b-alert.mt-3(variant="warning",show)
          p 提醒您 :
          ol
            li 若指定之顧問因不可抗因素無法授課，教務團隊將為你更換顧問，並即時通知您。
            //- li 若選擇未開放時段之顧問，須待顧問回覆同意後才算訂課成功
            //- for 舊合約使用者，有上傳檔案
            li(v-if="!customizedClassroomRule.new_rule && requireParams.materialFile") 【客製化課程】：上傳自備教材並可同時指定顧問，需於72小時前預約，每次扣除課堂數為兩堂，預約成功後不得取消課程。
            //- for 新合約使用者，客製化課程(指定老師、上傳檔案)
            li.text-danger.time-alert(v-if="customizedClassroomRule.new_rule && (consultantInfo.id || requireParams.materialFile)") {{ timeAlert() }}

        //- b-container
        //-   b-row.mt-5
        //-     div.remark
        //-       //- p {{$t('bookingRemark')}}
        //-       p(v-html="$t('appointBookingRemark')")
        //-       //- for 新約使用者提示，1對1提示訂課
        //-       //- 已指定老師
        //-       p.mt-2.notice(v-if="customizedClassroomRule.new_rule") 【一般課程-指定顧問、客製化課程】如「有」指定顧問或上傳自備教材，課程開始24小時前登入個人學習系統取消課程，不扣堂數；如於課程開始前「24小時內」預約指定顧問，則不得取消。

  template(#modal-footer="{ hide }")
    b-button.mt-3(v-if="step.active == 1",variant="primary" block @click="step.active = 2" key="next",:disabled="!enableSubmit") 下一步 / 確認課程資訊

    //- 第二步才送出
    b-button.mt-3(v-if="step.active == 2",variant="primary" block @click="step.active = 1" key="previous") 上一步
    b-button.mt-3(v-if="step.active == 2",variant="success" block @click="submit()" key="success") 確認送出訂課

  //- 確認送出 modal
  //- b-modal(id="confirmModal",hide-footer, centered)
  //-   h2 請確認是否送出訂課申請
  //-   .modal-footer
  //-     b-button(variant="danger", @click="$bvModal.hide('confirmModal');$bvModal.hide('bookConsultantTimeModal')") 取消
  //-     b-button(variant="success", @click="$bvModal.hide('confirmModal');$bvModal.hide('bookConsultantTimeModal');submit()") 送出
</template>

<script>
import i18n from '@/views/dojo/bookClass/requireConsultant/schedule/bookClassModal/lang';
import moment from 'moment';
import classroomConstants from '@/constants/classroom';
import lioshutanApi from '@/api';
import { alertSuccessMessage, alertFailedMessage } from '@/utils/sweetAlert.js';
// import httpCodeConstants from '@/constants/httpCode';
import closeIcon from '@/components/modal/normal/image/close.png';

export default {
  name: 'BookConsultantTimeModal',

  i18n,

  props: {
    // 顧問跟時間由父層傳入
    consultantInfo: {
      type: Object,
      default() {
        return {};
      },
    },
    bookingTime: {
      type: Object,
      default() {
        return {
          time: '',
          event: {},
        };
      },
    },
    newRule: {
      type: Boolean,
      default: true,
    },
    customizedClassroomRule: {
      type: Object,
      default() {
        return {};
      },
    },
  },

  data(){
    return {
      // 目前只處理是否上傳教材
      requireParams: {
        isUploadMaterial: false,
        materialFile: null,
      },

      // 訂課步驟
      step: {
        active: 1,
        enum: ['選擇課程設定', '再次確認課程'],
      },

      // 圖示
      closeIconUrl: closeIcon,
    };
  },

  computed: {
    enableSubmit() {
      // 規則為檢查自備教材
      let rule = true;
      if (this.requireParams.isUploadMaterial && !this.requireParams.materialFile) {
        rule = false;
      }
      return rule;
    },
  },

  watch: {
    'requireParams.isUploadMaterial': {
      handler(value) {
        if (!this.requireParams.isUploadMaterial) {
          this.requireParams.materialFile = null;
        }
      },
    },
  },

  methods: {
    // 目前先以單一訂課送出 (只有時間考量到批次訂課 須加上傳教材)
    async submit() {
      const bookSuccess = 1;
      const bookClasses = this.getBookClassesParams();
      const bookClassesFormData = this.getBookClassesFormData(bookClasses);
      try {
        const response = await lioshutanApi.bookingClass.bookingClass(bookClassesFormData);
        // TODO 需要寫陣列回傳api資訊
        if (response[0].status === bookSuccess){
          await alertSuccessMessage(this.$t('applySuccess'));
          this.$emit('applySuccess', true);
          this.$bvModal.hide('bookConsultantTimeModal');
          this.close();
        } else {
          alertFailedMessage(response[0].message);
        }
      } catch (error) {
        alertFailedMessage('applyDataFaild');
      }
      // this.resetParams();
    },

    getBookClassesParams(){
      const bookClasses = {
        ca: [], // class application
        ty: classroomConstants.BOOK_CLASS.TYPE,
      };
      // 目前只會有單一時間 可先直接塞入上傳檔案
      // this.bookingTime.forEach((bookTime) => {
      const bookClass = {
        class_time: this.bookingTime.time,
        class_type: (this.requireParams.materialFile) ? classroomConstants.BOOK_CLASS.CLASS_TYPE_SPECIFY_MATERIAL : classroomConstants.BOOK_CLASS.CLASS_TYPE_NORMAL,
        option: {
          consultant_id: this.consultantInfo.id,
          material_file: this.requireParams.materialFile,
        },
      };
      bookClasses.ca.push(bookClass);
      return bookClasses;
      // });
    },

    getBookClassesFormData(bookClasses){
      const formData = new FormData();
      formData.append('ty', bookClasses.ty);
      bookClasses.ca.forEach((classApplication, index) => {
        formData.append('ca[' + index + '][class_time]', classApplication.class_time);
        formData.append('ca[' + index + '][class_type]', classApplication.class_type);
        formData.append('ca[' + index + '][option][consultant_id]', classApplication.option.consultant_id);
        if (classApplication.option.material_file !== null){
          formData.append('ca[' + index + '][option][material_file]', classApplication.option.material_file);
        }
      });
      return formData;
    },

    async close(){
      this.resetParams();
      this.step.active = 1;
    },

    resetParams(){
      this.requireParams = {
        isUploadMaterial: false,
        materialFile: null,
      };
    },

    timeAlert() {
      const nextDay = moment().add(1, 'day');
      const bookingAt = moment(this.bookingTime.time);
      console.warn('isBefore', moment(bookingAt).isBefore(nextDay));

      // 判斷定課時間，是否於一天之內
      switch (moment(bookingAt).isBefore(nextDay)) {
        // 一天之內
        case true:
          return '此課程為24小時內之一對一指定課程，預約後不可取消喔！';
        // 一天之後
        default:
          return '此課程為24小時後之一對一指定課程，預約後如需取消，請於課前24小時前取消！';
      }
    },

    formatDate(date) {
      return moment(date).format('YYYY-MM-DD');
    },

    momentFormat(date, format) {
      return moment(date).format(format);
    },
  },
};
</script>

<style lang="scss" >
@import '@/styles/transition';
</style>
<style lang="scss" >
.modal-label{
  top: 10px;
}
.modal-text{
  font-size: 20px;
  font-weight: 600;
}
.modal-select{
  height: 40px;
  border-radius: 10px;
}

.step-bar{
  margin: 5px auto;
  width: 66%;
  padding: 10px 20px;
  display: flex;
  justify-content: space-between;
  position: relative;

  --circle-width: 40px;
  &::before{
    width: calc(100% - 3 * var(--circle-width)) ;
    height: 1px;
    position: absolute;
    top: 35%;
    left: calc(1.5 * var(--circle-width));
    content: '';
    background-color: lightgray;
  }

  .step{
    z-index: 2;
    display: flex;
    flex-direction: column;
    align-items: center;

    p {
      color: #6c757d;
      margin-top: 5px;
      margin-bottom: 0px;
    }
    &.active{
      p{
      color: #82c1ea;
      }
      .step-circle{
        background-color: #82c1ea;
        border-color: #82c1ea;
      }
    }

  }
  .step-circle{
    flex-shrink: 0;
    border-radius: 50%;
    width: var(--circle-width);
    height: var(--circle-width);

    color: #fff;

    background-color: #a4b7c1;
    border-color: #a4b7c1;

    display: flex;
    justify-content: center;
    align-items: center;

    transition: background-color 0.35s ease-out;
    &.active{
    background-color: #82c1ea;
    border-color: #82c1ea;
    }
  }
}

// 修改 b-modal 排版
.modal-footer{
  flex-wrap: nowrap;
}

.modal-header {
  display: block;
}
.normal-close-btn{
  width: 30px;
  cursor: pointer;
  float: right;
  position: relative;
  right: 0px;
}
.normal-modal-title{
  color: #366CFF;
  text-align: center;
  border: 1px solid #366CFF;
  border-radius: 15px;
  padding: 10px;
  font-size: 28px;
  font-weight: 600;
  letter-spacing: 4px;
  margin-top: -10px;
}
.normal-submit-btn{
  margin: 0 auto;
  border-radius: 8px;
}

.remark{
  color: red;
  margin-bottom: 3px;
  font-size: 13px;
  text-align: left;
  line-height: 16px;
}
.notice{
  color: red;
  text-align: start;
}

.time-alert{
  font-size: 18px;
}
</style>
