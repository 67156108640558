<template lang="pug">
div
  b-row.mt-5
    b-col
      b-skeleton.skeleton-box(width="30%")
  .wuwow-card.mt-5
    .wuwow-card-head
      h4.wuwow-card-title
        b-skeleton(width="30%")
    .wuwow-card-body.consultant-calendar-body
      b-row.mt-3
        b-col(cols="3")
          b-skeleton(type="avatar")
        b-col(cols="9")
          b-row
            b-col
              b-skeleton(width="100%")
            b-col
              b-skeleton(width="100%")
            b-col
              b-skeleton(width="100%")
          b-row.mt-3
            b-skeleton(width="100%")
      b-row.mt-3
        b-col
          b-skeleton-img
</template>

<script type="text/javascript">

export default {
  name: 'TeacherScheduleSkeleton',

};
</script>
<style>
.skeleton-box{
  margin: 0 auto;
}
</style>
