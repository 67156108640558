var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('b-row', {
    staticClass: "mt-5"
  }, [_c('b-col', [_c('b-skeleton', {
    staticClass: "skeleton-box",
    attrs: {
      "width": "30%"
    }
  })], 1)], 1), _c('div', {
    staticClass: "wuwow-card mt-5"
  }, [_c('div', {
    staticClass: "wuwow-card-head"
  }, [_c('h4', {
    staticClass: "wuwow-card-title"
  }, [_c('b-skeleton', {
    attrs: {
      "width": "30%"
    }
  })], 1)]), _c('div', {
    staticClass: "wuwow-card-body consultant-calendar-body"
  }, [_c('b-row', {
    staticClass: "mt-3"
  }, [_c('b-col', {
    attrs: {
      "cols": "3"
    }
  }, [_c('b-skeleton', {
    attrs: {
      "type": "avatar"
    }
  })], 1), _c('b-col', {
    attrs: {
      "cols": "9"
    }
  }, [_c('b-row', [_c('b-col', [_c('b-skeleton', {
    attrs: {
      "width": "100%"
    }
  })], 1), _c('b-col', [_c('b-skeleton', {
    attrs: {
      "width": "100%"
    }
  })], 1), _c('b-col', [_c('b-skeleton', {
    attrs: {
      "width": "100%"
    }
  })], 1)], 1), _c('b-row', {
    staticClass: "mt-3"
  }, [_c('b-skeleton', {
    attrs: {
      "width": "100%"
    }
  })], 1)], 1)], 1), _c('b-row', {
    staticClass: "mt-3"
  }, [_c('b-col', [_c('b-skeleton-img')], 1)], 1)], 1)])], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }