export default {
  messages: {
    tw: {
      consultantSchedule: '顧問行事曆',
      requireConsultantTime: '特殊時段申請',
      bookConsultantTime: '預約顧問時間',
      multipleBooking: '批次預約',
      submit: '送出',
      noTimeSelected: '尚未選擇時間',
      timeSelected: '已選擇時間',
      // 需確認內容 幾堂預約須從後端要
      bookingNotice: '您選擇 1 on 1 家教微課程，若與顧問預約成功，<br>將會扣除您的3堂堂數，並且無法被『取消』!',
      notAllowBookTime: '該時段無法訂課',
      notAllowConsultantBookTime: '該顧問此時段已被預約!!',
    },
    en: {
      consultantSchedule: 'Consultant Schedule',
      requireConsultantTime: 'Ask For The Time',
      bookConsultantTime: 'Booking Time',
      multipleBooking: 'Multiple Booking',
      submit: 'Submit',
      noTimeSelected: 'No time has been selected.',
      timeSelected: 'Selected Time',
      bookingNotice: 'You choose 1 on 1 tutoring micro-course,<br>if the appointment with the consultant is successful,<br>3 lessons will be deducted from you!<br>and cannot be "cancelled"<br>',
      notAllowBookTime: 'Can\'t book at this time!',
      notAllowConsultantBookTime: 'Can\'t book at this time!',
    },
  },
};
