<template lang="pug">
  div
    skeleton(:loading="loading")
      template(slot="skeleton-template")
        skeleton-template
      template(slot="origin-template")
        server-time-clock
        .wuwow-card
          .wuwow-card-head
            h4.wuwow-card-title {{ $t('consultantSchedule') }}
          .wuwow-card-body.consultant-calendar-body
            b-row
              b-col(cols="12" md="3")
                .img-consultant-pictrue
                  img(:src="consultantInfo.picture" :alt="consultantInfo.name" :title="consultantInfo.name")
              b-col(cols="12" md="9").title-text
                h3.mb-4 {{ consultantInfo.name }}
                b-alert.text-center(variant="warning" show) 點擊 + 進行顧問未開放時段之申請，需經顧問同意後始完成訂課
            full-calendar(ref="vue-calendar" :options="calendarOptions")
            b-container.bv-example-row
              b-row.mt-3(align-h="end")
                b-col.text-center(cols="6" md="9" lg="10")
                  button.btn.btn-outline-primary.btn-consultant-back(type='button' @click="back()") 返 回
                b-col.float-right(cols="6" md="3" lg="2" v-b-modal.requireConsultantTimeModal)
                  font-awesome-icon.calendar-plus-icon.mr-2(icon="calendar-plus" size="2x")
                  span.medium-text {{ $t('requireConsultantTime') }}
                //- 批次訂課按鈕
                //- b-col.float-right(cols="3")
                //-   b-form-checkbox.multiple-book-checkbox.mr-3(v-model="mutipleRequire" size="lg") {{ $t('multipleBooking') }}
                //-   transition(name="fade")
                //-     b-button.mr-3(v-if="mutipleRequire" variant="primary" v-b-modal.bookConsultantTimeModal) {{ $t('submit') }}
    require-consultant-time-modal(:consultantInfo="{name: consultantInfo.name, id: hbConsultantId}", :customizedClassroomRule="customizedClassroomRule")
    book-consultant-time-modal(
      :bookingTime="bookTimes[0]"
      :consultantInfo="{name: consultantInfo.name, id: hbConsultantId}"
      @applySuccess="applySuccess"
      :newRule="newRule",
      :customizedClassroomRule="customizedClassroomRule",
    )
    //- TOSO 拉component 跟 jill dobby討論如何共用 By Asa
    //- props bookTimes(Object) removeSelectedTime(function)
    //- b-modal(ref="bookConsultantTimeModal" id="bookConsultantTimeModal" centered title-tag="h3" :title="$t('bookConsultantTime')" :ok-title="$t('submit')" :cancel-title="$t('cancel')" @ok="submit(hbConsultantId)")
    //-   div
    //-     h5 {{ `${$t('consultant')}: ${ consultantInfo.name }` }}
    //-     hr
    //-     div.text-center
    //-       b-badge.mb-2.p-2(v-if="bookTimes.length == 0" variant="danger")
    //-         span {{ $t('noTimeSelected') }}
    //-       b-badge.mb-2.p-2(v-else variant="primary")
    //-         span {{ $t('timeSelected') }}
    //-           font-awesome-icon.ml-2(:icon="['far', 'clock']")
    //-       transition-group(name="fade")
    //-         h5.text-dark(v-for="item in sortBookTimes" :key="item.time")
    //-           font-awesome-icon.mr-2(:icon="['far', 'calendar']")
    //-           | {{ item.time }}
    //-           b-button.ml-2(size="sm" variant="outline-danger" @click="removeSelectedTime(item.element, item.time)")
    //-             font-awesome-icon(icon="trash")
    //-     b-alert.text-center(show variant="warning" v-html="$t('bookingNotice')")
    //- Modal End
    //- 切換月份切換 modal
    b-modal#monthModal(
    v-model="monthModal"
    @ok="switchMonth()"
    :title="'訊息'"
    :ok-title="'確定'"
    :cancel-title="'取消'"
    centered
    header-bg-variant="primary"
  )
      h4.text-center
        | 是否跳至
        span.text-danger {{ formatMonth(toMonth) }}
        | 月進行訂課
</template>

<script>
// 防呆 :日期須再加上反灰跟click disable
import moment from 'moment';
import { getDateBetween } from '@/utils/moment';
import fullCalendar from '@fullcalendar/vue';
import dayGridPlugin from '@fullcalendar/daygrid';
import interactionPlugin from '@fullcalendar/interaction';
import i18n from '@/views/dojo/bookClass/requireConsultant/schedule/lang';
import { getDateWithTimeSecond } from '@/utils/moment';
import { sortBy, find, filter, forEach, intersectionBy, chain } from 'lodash';

import skeleton from '@/components/skeleton/index.vue';
import serverTimeClock from '@/components/serverTimeClock';
import requireConsultantTimeModal from '@/views/dojo/bookClass/requireConsultant/schedule/bookClassModal/requireConsultantTimeModal/index.vue';
import bookConsultantTimeModal from '@/views/dojo/bookClass/requireConsultant/schedule/bookClassModal/bookConsultantTimeModal/index.vue';
import skeletonTemplate from '@/views/dojo/bookClass/requireConsultant/schedule/skeleton/index.vue';

import platformConstant from '@/constants/platform';
import { alertFailedMessage } from '@/utils/sweetAlert.js';
import lioshutanApi from '@/api';
import { mapState } from 'vuex';

const bookEventStyle = {
  backgroundColor: '#E5E5E5',
  className: ['event-box'],
};
const scheduledBookEventStyle = {
  backgroundColor: '#E5E5E5',
  className: ['scheduled-event-box'],
};
// const disableBookEventStyle = {
//   backgroundColor: '#E5E5E5',
//   className: ['event-box'],
// };
const currentPalaform = platformConstant.WUWOW_JUNIOR;

export default {
  name: 'RequireConsultantSchedule',

  components: {
    fullCalendar,
    bookConsultantTimeModal,
    requireConsultantTimeModal,
    serverTimeClock,
    skeleton,
    skeletonTemplate,
  },

  i18n,

  data() {
    return {
      newRule: true,
      loading: false,
      hbConsultantId: this.$route.params.hbConsultantId,
      // 客製化課程規則
      customizedClassroomRule: {},
      // 顧問個人資料須從後端接API
      consultantInfo: {},
      // 可以拉component 讓團隊方便使用
      // 需要新增不能選擇日期反灰
      calendarOptions: {
        plugins: [dayGridPlugin, interactionPlugin],
        // timeZone: 'local',
        initialView: 'dayGridMonth',
        height: 'auto',
        headerToolbar: {
          left: '',
          center: 'title',
          right: 'customPrev,customNext today',
        },
        buttonText: {
          today: 'Today',
        },
        // 自訂下一頁按鈕
        customButtons: {
          customPrev: {
            icon: 'chevron-left',
            click: this.customPrev,
          },
          customNext: {
            icon: 'chevron-right',
            click: this.customNext,
          },
        },
        dateClick: this.onDayClick,
        events: this.handleEvents,
        eventClick: this.handleEventClick,
        eventTimeFormat: {
          hour: '2-digit',
          minute: '2-digit',
          hour12: false,
        },
        locale: 'zh-tw',
      },
      timeslot: [],
      mutipleRequire: false,

      // bookConsultantTimeModal的bookTime
      bookTimes: [],
      calendarApi: null,

      // 切換月份切換 modal
      monthModal: false,
      toMonth: moment().format(),
    };
  },

  async created() {
    // 先移除skeleton 因為 calendarApi 需要再dom繪製完畢就拿到api
    // this.startLoading();
    await this.getCurrentConsultantInfo(this.hbConsultantId);
    // this.endLoading();
  },

  computed: {
    ...mapState({
      currentTime: state => state.common.currentTime,
    }),
    // 依照時間排序批次訂課時間
    sortBookTimes() {
      // 使用 Lodash sortBy 排序時間
      return sortBy(this.bookTimes, [
        function(object) {
          return object.time;
        },
      ]);
    },
  },

  async mounted() {
    this.calendarApi = this.$refs['vue-calendar']['getApi']();
  },

  watch: {
    /**
     * 關閉批次預約清空所有預約時間
     */
    mutipleRequire() {
      this.removeAllSelectedTime();
    },
  },

  methods: {
    back() {
      this.$router.back();
    },

    startLoading(){
      this.loading = true;
    },

    endLoading(){
      this.loading = false;
    },

    async getStudentPorfile(){
      const result = await lioshutanApi.vip.getBaseProfile();
      this.customizedClassroomRule = result.data.customized_classroom_rule;
      // this.customizedClassroomRule.new_rule = false;
    },

    formatMonth(timeString) {
      return moment(timeString).format('M');
    },
    // 自訂上一月按鈕
    customPrev() {
      // 電腦當月及下一月
      const currentMonth = moment().add(0, 'months').format('M');
      const nextMonth = moment().add(1, 'months').format('M');

      const prevTo = moment(this.calendarApi.view.currentStart).add(-1, 'months').format();
      this.toMonth = prevTo;

      if (moment(prevTo).format('M') === currentMonth || moment(prevTo).format('M') === nextMonth) {
        this.monthModal = true;
        return;
      }
      this.switchMonth();
    },
    // 自訂下一月按鈕
    customNext() {
      // 電腦當月及下一月
      const currentMonth = moment().add(0, 'months').format('M');
      const nextMonth = moment().add(1, 'months').format('M');

      const nextTo = moment(this.calendarApi.view.currentStart).add(1, 'months').format();
      this.toMonth = nextTo;

      if (moment(nextTo).format('M') === currentMonth || moment(nextTo).format('M') === nextMonth) {
        this.monthModal = true;
        return;
      }
      this.switchMonth();
    },

    // full calendar 切換至月份
    switchMonth() {
      this.calendarApi.gotoDate(this.toMonth);
    },

    onDayClick(value) {
      // 日曆日期與點擊日期不同月跳出月份切換 modal
      const calendarMonth = moment(this.calendarApi.view.currentStart).format('M');
      const clickTo = moment(value.date).format();

      // 今天以前點擊不會跳出月份切換 modal
      const isBeforeToday = moment(clickTo).isBefore(moment().format());
      if (isBeforeToday) {
        return;
      }

      if (moment(clickTo).format('M') !== calendarMonth) {
        this.toMonth = clickTo;
        this.monthModal = true;
        return;
      }
    },

    async handleEvents({ start, end }, success, fail) {
      // 目前縱使把時間換成當天後端依然會把時間 72小後
      await this.getStudentPorfile();
      this.start = start;
      this.end = end;
      // const timeFormat = 'YYYY-MM-DD HH:mm:ss';
      // 因為生命週期的關係這時候還沒取回vuex的serveTime 因此在這裡先呼叫 以便呼叫API時可以丟起始日期過去
      const response = await lioshutanApi.common.getServerTime();
      const serveTime = response.data;

      // 新學員只要是下一個時段就可以選顧問，
      // 當天: 不能選教材
      // 72小時後才可以選
      // 如果月曆的起始日比現在晚 搜尋的起始日為月曆的起始日 否則為現在+3天(客製化訂課72小時搜尋)
      const startTime = this.customizedClassroomRule.new_rule
        ? serveTime
        : (moment(start).isBefore(moment(serveTime, 'second')))
          ? moment(serveTime).add(3, 'days')
          : start;
      try {
        const response = await this.setConsultantTimeslots(startTime, end, this.hbConsultantId);
        success(response);
      } catch (error) {
        fail(error);
      }
    },

    async setConsultantTimeslots(start, end, hbConsultantId) {
      const timeslotEvents = [];
      const startDay = moment(start).format('YYYY-MM-DD');
      const endDay = moment(end).format('YYYY-MM-DD');
      const dateArray = getDateBetween(startDay, endDay);
      const allowTimeslots = await this.getClassTimeslots(currentPalaform, dateArray);
      const params = {
        teacherId: hbConsultantId,
        startDay: startDay,
        endDay: endDay,
        platform: currentPalaform,
        customizedHourFilterFlag: true, // 加了之後後端會判斷當前時間72小時之後的時段才丟過來
      };
      if (this.customizedClassroomRule.new_rule) {
        // 新身分帶 false 不然預設還是 72小時候
        params.customizedHourFilterFlag = false;
        // 改系統當天
        params.startDay = moment(this.currentTime).format('YYYY-MM-DD');
      }
      const response = await lioshutanApi.personnel.queryConsultantTimeslots(params);
      response.data.data.timeslots.forEach((timeslot) => {
        // 允許被訂課的狀態
        const allowBooking = 1;
        // TODO endTime需要從後端傳入 防止event溢位加上結束時間
        const endTime = moment(timeslot.booking_time).add(1, 'seconds').format('YYYY-MM-DD HH:mm:ss');
        const status = timeslot.status;
        if (status === allowBooking) {
          // 判斷的是否被其他人定課走
          timeslotEvents.push({ start: timeslot.booking_time, end: endTime, ...bookEventStyle, allow: true });
        } else {
          timeslotEvents.push({ start: timeslot.booking_time, end: endTime, ...scheduledBookEventStyle, allow: false });
        }
      });
      const newAllowTimeslots = [];
      forEach(allowTimeslots, (value, key) => {
        value.forEach((timeslot) => {
          newAllowTimeslots.push({ start: `${key} ${timeslot.time}:00` });
        });
      });
      const filterTimeslotEvents = intersectionBy(timeslotEvents, newAllowTimeslots, 'start');
      // 過年日期直接過濾不渲染
      const newYearDate = ['2024-02-07 08:59', '2024-02-15 08:59'];
      return filterTimeslotEvents
        .filter((item) => this.sortTimeArea(item.start))
        // 過年日期不開放
        .filter((item) => !moment(item.start).isBetween(newYearDate[0], newYearDate[1], 'second'));
    },

    sortTimeArea(strTime) {
      const currentTime = this.currentTime;
      const TIME_RANGE = 'second';
      const serveTimeMorning = {
        // 當天早上 11 點前
        condition: moment(currentTime).format('YYYY-MM-DD 11:00:00'),
        conditionRangeStart: moment(currentTime).format('YYYY-MM-DD 12:29:59'),
        conditionRange: (timeStr) => moment(timeStr).isAfter(serveTimeMorning.conditionRangeStart, TIME_RANGE),
      };
      const serveTimeAfternoon = {
        // 當天下午 17 點前
        condition: moment(currentTime).format('YYYY-MM-DD 17:00:00'),
        conditionRangeStart: moment(currentTime).format('YYYY-MM-DD 18:29:59'),
        conditionRange: (timeStr) => moment(timeStr).isAfter(serveTimeAfternoon.conditionRangeStart, TIME_RANGE),
      };
      const serveTimeNoon = {
        // 當天晚上 9 點前
        condition: moment(currentTime).format('YYYY-MM-DD 21:00:00'),
        conditionRangeStart: moment(currentTime).format('YYYY-MM-DD 23:59:59'),
        conditionRange: (timeStr) => moment(timeStr).isAfter(serveTimeNoon.conditionRangeStart, TIME_RANGE),
      };

      const serveDefault = {
        // 九點以後
        conditionRangeStart: moment(currentTime).add(1, 'days').format('YYYY-MM-DD 12:29:59'),
        conditionRange: (timeStr) => {
          return moment(timeStr).isAfter(serveDefault.conditionRangeStart);
        },
      };
      // 1. 現在在哪一個時區範圍
      const timeArea = chain([serveTimeMorning, serveTimeAfternoon, serveTimeNoon])
        .filter((item) => {
          return moment(currentTime).isBefore(item.condition, TIME_RANGE);
        })
        .head()
        .value() || serveDefault;
      if (moment(strTime).isBefore(currentTime, TIME_RANGE)) {
        return false;
      }
      return timeArea.conditionRange(strTime);
    },

    async getClassTimeslots(platform = currentPalaform, dateArray){
      const params = {
        days: dateArray,
        platform: platform,
        splitSection: false,
      };
      const result = await lioshutanApi.bookingClass.getClassTimeslots(params);
      return result.data.data.timeslots;
    },

    async getCurrentConsultantInfo(hbConsultantId) {
      try {
        const response = await lioshutanApi.personnel.queryConsultantDetail(
          hbConsultantId
        );
        this.consultantInfo = response.data.data.hb_profile;
      } catch (error) {
        console.log(error);
      }
    },
    // el event回傳的物件名稱 取得schedule上面事件的html dom
    handleEventClick(value) {
      const time = getDateWithTimeSecond(value.event.start);
      if (value.event.allow){
        if (this.allowBookTime(time)){
          if (this.mutipleRequire) {
            this.mutipleEventSelect(value.el, time);
          } else {
            // 判斷是否要顯示教材選項 當天: 不能選教材
            if (this.customizedClassroomRule?.new_rule) { // 是否新身分
              const startTime = moment(this.currentTime).add(3, 'days').format('YYYY-MM-DD HH:mm');
              // 系統與當地時間比較 是否打開指定教材
              // 但是還是有一種情況是當天到 72 天之間的選項
              // 系統時間 + 3 天 以前的教材都不會打開
              if (moment(startTime).isAfter(value.event.start, 'minute')) {
                this.newRule = false;
              } else {
                this.newRule = true;
              }
            }
            this.singleEventSelected(value.el, time);
          }
        } else {
          alertFailedMessage(this.$t('notAllowBookTime'));
        }
      } else {
        alertFailedMessage(this.$t('notAllowConsultantBookTime'));
      }
    },

    singleEventSelected(element, time) {
      this.bookTimes = [];
      this.addSelectTime(element, time);
      this.$root.$emit('bv::show::modal', 'bookConsultantTimeModal');
    },

    mutipleEventSelect(element, time) {
      if (this.timeIsNotSelected(time)) {
        element.classList.add('selected-event');
        this.addSelectTime(element, time);
      } else {
        this.removeSelectedTime(element, time);
      }
    },

    addSelectTime(element, time) {
      const timeEvent = {
        element: element,
        time: time,
      };
      this.bookTimes.push(timeEvent);
    },

    timeIsNotSelected(time) {
      // 使用 Lodash find判斷陣列item是否存在
      return !find(this.bookTimes, ['time', time]);
    },

    // bookConsultantTimeModal 會使用到該方法
    removeSelectedTime(element, time) {
      element.classList.remove('selected-event');
      // 使用 Lodash filter 篩選需要的物件
      this.bookTimes = filter(this.bookTimes, function(item) {
        return item.time !== time;
      });
    },

    // 移除所有多選時間
    removeAllSelectedTime() {
      this.bookTimes.forEach((value) => {
        value.element.classList.remove('selected-event');
      });
      this.bookTimes = [];
    },

    // 可訂課日期 -第一層防呆
    allowBookTime(time){
      // TODO 需改成系統時間
      const clickTime = moment(time);
      const newRule = this.customizedClassroomRule?.new_rule;
      const defaultDay = moment(this.currentTime).add(3 * 24, 'hours');
      const newRuleDay = moment(this.currentTime);
      const allowBookTime =
        (clickTime.isAfter(newRule ? newRuleDay : defaultDay) &&
          clickTime.isBefore(moment(this.currentTime).add(30 * 24, 'hours')));
      return allowBookTime;
    },

    applySuccess(){
      this.calendarApi.refetchEvents();
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@/styles/transition';
.consultant-calendar-body {
  position: relative;
}
.title-text {
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-shadow: 1px 3px 6px rgba(0, 0, 0, 0.2);
}
.img-consultant-pictrue {
  width: 150px;
  height: 150px;
  border-radius: 50%;
  overflow: hidden;
  margin: 0 auto;
  img {
    max-width: 100%;
  }
}
// 行事曆
::v-deep .fc {
  margin-top: 25px;
}
::v-deep .fc-event{
  display: flex;
}
::v-deep .fc-toolbar.fc-header-toolbar {
  position: absolute;
  top: 45px;
  right: 30px;
  width: 60%;
}
::v-deep .fc-daygrid-event-harness {
  cursor: pointer;
}

// 批次訂課按鈕
::v-deep .multiple-book-checkbox {
  padding-left: 0 !important;
}

// 行事曆事件
::v-deep .scheduled-event-box {
  background: #4F4F4F;
  color: #ffffff;
  transition: all 0.4s ease-out;
}
::v-deep .event-box {
  background: #1255a0;
  color: #ffffff;
  transition: all 0.4s ease-out;
}

::v-deep .event-box:hover {
  background: #2d4164;
  color: #ffffff;
}

.selected-event {
  background: #7cb983;
}

.medium-text {
  font-size: 16px;
}

// 非開放時段訂課icon
.calendar-plus-icon {
  cursor: pointer;
  color: #006bda;
  transition: all 0.4s ease-in-out;
}

.calendar-plus-icon:hover {
  color: #004fa3;
}

@media screen and ( max-width: 767px ){
  ::v-deep .fc-toolbar.fc-header-toolbar {
    position: initial;
    top: 0;
    right: 0;
    width: 100%;
    margin: 0 auto;
  }
  .title-text{
    text-align: center;
  }
}
</style>
