export default {
  messages: {
    tw: {
      normalBooking: '一般訂課',
      requireConsultantTime: '特殊時段申請',
      selectDate: '請選擇日期',
      selectTime: '請選擇時間',
      selectTimeSection: '請選擇時段',
      bookingRemark: '《非開放時段訂課屬於特殊時段申請，須消耗堂數 2 堂》',
      appointBookingRemark: '備註：<br>一、若選擇顧問之未開放時段，須待顧問回覆同意後才算訂課成功。<br>二、若指定之顧間因不可抗因素無法授課,教務團隊將依照您的喜好顧問列表為你更換顧問，並即時通知您。',
      requireConsultantTimeRemark: '《註：若選擇未開放時段之顧問，須待顧問回覆同意後才算訂課成功。》',
      appointrequireConsultantRemark: '《註: 特殊時段申請須消耗堂數 2 堂，可向顧問申請額外加開授課時段(需符合原訂WUWOW Jr開課時段，但顧問未開放授課之條件)，可同時上傳自備教材。》<br><br>《註: 須待顧問回覆同意後才算訂課成功。》<br><br>《註: 。顧問將於課前36小時於系統回覆是否接受加開申請，如未申請成功，則不需計算堂數。如預約成功，則不得取消課程。》<br><br>《註：若指定之顧問因不可抗因素無法授課，教務團隊將為你更換顧問，並即時通知您。》',
      designatedConsultant: '指定顧問',
      designatedDate: '指定日期',
      designatedTime: '指定時段',
      designatedMaterial: '指定自備教材',
      uploadYourFile: '上傳你的檔案',
      designatedMaterialNotice: '<u>注意：自備教材為客製化課程，將消耗堂數 <span class="text-danger font-weight-bold">2 堂</span></u><br><br>僅接受格式為 "ppt,pptx" 且大小 5MB 以下的檔案，請務必上傳英文内容之教材，並避免敏感及爭議性話題。',
      morning: '早上',
      afternoon: '下午',
      evening: '晚上',
      sendSuccess: '送出成功',
      sendFail: '送出失敗',
      applySuccess: '申請成功',
      applyFaild: '申請失敗',
      applyDataFaild: '申請資料有誤',
    },
    en: {
      normalBooking: 'Normal Booking',
      requireConsultantTime: 'Ask For The Time',
      selectDate: 'Select Date',
      selectTime: 'Select Time',
      selectTimeSection: 'Select Time Section',
      bookingRemark: '《Reservation of classes during non-open hours is a special time slot application and must consume 2 classes》',
      appointBookingRemark: 'Remarks: <br>1. If you choose a time slot that is not open for the consultant, you must wait for the consultant to reply before you can book a course successfully. <br>2. If the designated advisor is unable to teach due to force majeure, the educational administration team will replace the advisor for you according to your preferred advisor list and notify you immediately.',
      requireConsultantTimeRemark: '《Note: If you choose a consultant with an unopened time period, you will not be able to book a course until the consultant replies and agrees.》',
      appointrequireConsultantRemark: '《Note: If the designated advisor is unable to teach due to force majeure, the educational administration team will replace the advisor for you according to your preferred advisor list and notify you immediately.》',
      designatedConsultant: 'Designated Consultant',
      designatedDate: 'Designated Date',
      designatedTime: 'Designated Time',
      designatedMaterial: 'Designated Material',
      uploadYourFile: 'Upload Your File',
      designatedMaterialNotice: 'If you choose a specific course of the course, two pieces are required',
      morning: 'Morning',
      afternoon: 'Afternoon',
      evening: 'Evening',
      sendSuccess: 'Sent Successfully',
      sendFail: 'Sent Failed',
      applySuccess: 'Apply Successfully',
      applyFaild: 'Apply Failed',
      applyDataFaild: 'Apply Data Faild !',
    },
  },
};
