<template lang="pug">
  b-modal.normal-modal(:id="modalId" :size="size" :ref="modalId" centered :title="$t('requireConsultantTime')" no-close-on-backdrop='' @ok="ok()" @close="close")
    template(#modal-header="{ close }")
      img.normal-close-btn(:src="closeIconUrl" @click="close()")
    template(#default)
      h2.normal-modal-title {{ modalTitle }}
      slot(name="modal-body")
    template(#modal-footer="{ hide }")
      b-button.normal-submit-btn(variant="success" size="sm" @click="ok()")
        font-awesome-icon(:icon="['fa', 'check']" size="2x")
</template>

<script>
// example: normal-modal(:modalId="'requireConsultantTimeModal'" :modalTitle="'非開放時段訂課'" @submit="submit" @close="close")
import closeIcon from '@/components/modal/normal/image/close.png';
export default {
  name: 'NormalModal',

  props: {
    modalId: {
      type: String,
    },
    modalTitle: {
      type: String,
    },
    size: {
      type: String,
      default: 'md',
    },
  },

  data(){
    return {
      closeIconUrl: closeIcon,
    };
  },

  methods: {
    ok(){
      this.$emit('submit', true);
    },
    close(){
      this.$emit('close', true);
    },
  },
};
</script>

<style>
.modal-header {
  display: block;
}
.normal-close-btn{
  width: 30px;
  cursor: pointer;
  float: right;
  position: relative;
  right: 0px;
}
.normal-modal-title{
  color: #366CFF;
  text-align: center;
  border: 1px solid #366CFF;
  border-radius: 15px;
  padding: 10px;
  font-size: 28px;
  font-weight: 600;
  letter-spacing: 4px;
  margin-top: -10px;
}
.normal-submit-btn{
  margin: 0 auto;
  border-radius: 8px;
}
</style>
