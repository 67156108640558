var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-modal', {
    ref: _vm.requireConsultantTimeModal,
    attrs: {
      "id": "requireConsultantTimeModal",
      "size": 'lg',
      "no-close-on-backdrop": "",
      "centered": ""
    },
    on: {
      "close": _vm.close
    },
    scopedSlots: _vm._u([{
      key: "modal-header",
      fn: function (_ref) {
        var close = _ref.close;
        return [_c('img', {
          staticClass: "normal-close-btn",
          attrs: {
            "src": _vm.closeIconUrl
          },
          on: {
            "click": function ($event) {
              return close();
            }
          }
        })];
      }
    }, {
      key: "default",
      fn: function () {
        return [_c('h2', {
          staticClass: "normal-modal-title"
        }, [_vm._v(_vm._s(_vm.$t('requireConsultantTime')))]), _c('div', {
          staticClass: "step-bar"
        }, _vm._l(_vm.step.enum, function (item, index) {
          return _c('div', {
            staticClass: "step",
            class: {
              active: _vm.step.active == index + 1
            }
          }, [_c('div', {
            staticClass: "step-circle"
          }, [_vm._v(_vm._s(index + 1))]), _c('p', [_vm._v(_vm._s(item))])]);
        }), 0), _c('transition', {
          attrs: {
            "name": "fade",
            "mode": "out-in"
          }
        }, [_vm.step.active == 1 ? _c('section', {
          key: "1"
        }, [_c('b-container', [_c('b-row', {
          staticClass: "mt-3"
        }, [_c('b-col', {
          staticClass: "modal-label",
          attrs: {
            "cols": "3"
          }
        }, [_vm._v(_vm._s(_vm.$t('designatedConsultant')))]), _c('b-col', {
          staticClass: "mt-1 modal-text",
          attrs: {
            "cols": "9"
          }
        }, [_vm._v(_vm._s(_vm.consultantInfo.name))])], 1), _c('b-row', {
          staticClass: "mt-3"
        }, [_c('b-col', {
          staticClass: "modal-label",
          attrs: {
            "cols": "3"
          }
        }, [_vm._v(_vm._s(_vm.$t('designatedDate')))]), _c('b-col', {
          attrs: {
            "cols": "9"
          }
        }, [_c('select-date', {
          attrs: {
            "date": _vm.requireParams.date,
            "disabledDate": _vm.disabledDate,
            "placeholder": _vm.$t('selectDate')
          },
          on: {
            "update:date": function ($event) {
              return _vm.$set(_vm.requireParams, "date", $event);
            },
            "changeDate": _vm.changeDate
          }
        })], 1)], 1), _vm.requireParams.date ? _c('b-row', {
          staticClass: "mt-3"
        }, [_c('b-col', {
          staticClass: "modal-label",
          attrs: {
            "cols": "3"
          }
        }, [_vm._v(_vm._s(_vm.$t('designatedTime')))]), _c('b-col', {
          attrs: {
            "cols": "9"
          }
        }, [_c('b-row', [_c('b-col', {
          attrs: {
            "cols": "6"
          }
        }, [_c('b-form-select', {
          staticClass: "modal-select",
          attrs: {
            "options": _vm.timeSectionOption
          },
          on: {
            "change": _vm.changeTimeSection
          },
          model: {
            value: _vm.requireParams.timeSection,
            callback: function ($$v) {
              _vm.$set(_vm.requireParams, "timeSection", $$v);
            },
            expression: "requireParams.timeSection"
          }
        })], 1), _vm.requireParams.timeSection ? _c('b-col', {
          attrs: {
            "cols": "6"
          }
        }, [_c('b-form-select', {
          staticClass: "modal-select",
          attrs: {
            "options": _vm.requireTimeOptions
          },
          model: {
            value: _vm.requireParams.time,
            callback: function ($$v) {
              _vm.$set(_vm.requireParams, "time", $$v);
            },
            expression: "requireParams.time"
          }
        })], 1) : _vm._e()], 1)], 1)], 1) : _vm._e(), _c('b-row', {
          staticClass: "mt-3"
        }, [_c('b-col', {
          staticClass: "modal-label",
          attrs: {
            "cols": "3"
          }
        }, [_vm._v(_vm._s(_vm.$t('designatedMaterial')))]), _c('b-col', {
          attrs: {
            "cols": "9"
          }
        }, [_c('b-row', {
          staticClass: "mt-2"
        }, [_c('b-col', {
          attrs: {
            "cols": "2"
          }
        }, [_c('b-form-checkbox', {
          attrs: {
            "value": 1,
            "unchecked-value": 0
          },
          on: {
            "change": _vm.changeUploadMaterialCheckbox
          },
          model: {
            value: _vm.requireParams.isUploadMaterial,
            callback: function ($$v) {
              _vm.$set(_vm.requireParams, "isUploadMaterial", $$v);
            },
            expression: "requireParams.isUploadMaterial"
          }
        })], 1), _c('b-col', {
          attrs: {
            "cols": "10"
          }
        }, [_vm.requireParams.isUploadMaterial === 1 ? _c('b-form-file', {
          attrs: {
            "state": Boolean(_vm.requireParams.materialFile),
            "placeholder": _vm.$t('uploadYourFile')
          },
          model: {
            value: _vm.requireParams.materialFile,
            callback: function ($$v) {
              _vm.$set(_vm.requireParams, "materialFile", $$v);
            },
            expression: "requireParams.materialFile"
          }
        }) : _vm._e(), _vm.requireParams.materialFile ? _c('p', {
          staticClass: "mt-1 text-success"
        }, [_vm._v("已選擇檔案： " + _vm._s(_vm.requireParams.materialFile.name))]) : _vm._e()], 1)], 1), _c('b-row', {
          staticClass: "mt-2"
        }, [_c('b-col', [_vm.requireParams.isUploadMaterial ? _c('b-alert', {
          attrs: {
            "show": "",
            "variant": "warning"
          }
        }, [_c('span', {
          domProps: {
            "innerHTML": _vm._s(_vm.$t('designatedMaterialNotice'))
          }
        })]) : _vm._e()], 1)], 1)], 1)], 1)], 1)], 1) : _vm._e(), _vm.step.active == 2 ? _c('section', {
          key: "2"
        }, [_c('b-list-group', [_c('b-list-group-item', [_vm._v("日期：" + _vm._s(_vm.formatDate(_vm.requireParams.date)))]), _c('b-list-group-item', [_vm._v("時段：" + _vm._s(_vm.findOption(_vm.timeSectionOption, 'value', _vm.requireParams.timeSection, 'text')))]), _c('b-list-group-item', [_vm._v("時間：" + _vm._s(_vm.formatTime(_vm.requireParams.time)))]), _c('b-list-group-item', [_vm._v("指定顧問：" + _vm._s(_vm.consultantInfo.name))]), _vm.requireParams.isUploadMaterial ? _c('b-list-group-item', [_vm._v("指定自備教材： " + _vm._s(_vm.requireParams.materialFile ? _vm.requireParams.materialFile.name : '--'))]) : _vm._e()], 1), !_vm.customizedClassroomRule.new_rule ? _c('b-alert', {
          staticClass: "mt-3",
          attrs: {
            "variant": "warning",
            "show": ""
          }
        }, [_c('p', [_vm._v("提醒您 :")]), _c('ol', [_c('li', [_c('span', {
          staticClass: "text-danger"
        }, [_vm._v("特殊時段申請須消耗堂數 2 堂")]), _c('span', [_vm._v("，可向顧問申請額外加開授課時段(需符合原訂WUWOW Jr開課時段，但顧問未開放授課之條件)，可同時上傳自備教材。")])]), _c('li', [_vm._v("須待顧問回覆同意後才算訂課成功，也才會扣除堂數。")]), _c('li', [_c('span', [_vm._v("3. 顧問將於課前36小時於系統回覆是否接受加開申請， 如未申請成功，則不需計算堂數。")]), _c('span', {
          staticClass: "text-danger"
        }, [_vm._v("如預約成功，則不得取消課程。")])]), _c('li', [_vm._v("若指定之顧問因不可抗因素無法授課，教務團隊將為你更換顧問，並即時通知您。")])])]) : _vm._e(), _vm.customizedClassroomRule.new_rule ? _c('b-alert', {
          staticClass: "mt-3",
          attrs: {
            "variant": "warning",
            "show": ""
          }
        }, [_c('p', [_vm._v("提醒您 :")]), _c('ol', [_c('li', [_c('span', {
          staticClass: "text-danger"
        }, [_vm._v("特殊時段申請須消耗堂數 2 堂")]), _c('span', [_vm._v("，可向顧問申請額外加開授課時段(需符合原訂WUWOW Jr開課時段，但顧問未開放授課之條件)，可同時上傳自備教材。")])]), _c('li', [_vm._v("須待顧問回覆同意後才算訂課成功，也才會扣除堂數。")]), _c('li', [_c('span', [_vm._v("顧問將於課前36小時於系統回覆是否接受加開申請， 如未申請成功，則不需計算堂數。")]), _c('span', {
          staticClass: "text-danger"
        }, [_vm._v("如預約成功，則不得取消課程。")])]), _c('li', [_vm._v("若指定之顧問因不可抗因素無法授課，教務團隊將為你更換顧問，並即時通知您。")])])]) : _vm._e()], 1) : _vm._e()])];
      },
      proxy: true
    }, {
      key: "modal-footer",
      fn: function (_ref2) {
        var hide = _ref2.hide;
        return [_vm.step.active == 1 ? _c('b-button', {
          key: "next",
          staticClass: "mt-3",
          attrs: {
            "variant": "primary",
            "block": "",
            "disabled": !_vm.enableSubmit
          },
          on: {
            "click": function ($event) {
              _vm.step.active = 2;
            }
          }
        }, [_vm._v("下一步 / 確認課程資訊")]) : _vm._e(), _vm.step.active == 2 ? _c('b-button', {
          key: "previous",
          staticClass: "mt-3",
          attrs: {
            "variant": "primary",
            "block": ""
          },
          on: {
            "click": function ($event) {
              _vm.step.active = 1;
            }
          }
        }, [_vm._v("上一步")]) : _vm._e(), _vm.step.active == 2 ? _c('b-button', {
          key: "success",
          staticClass: "mt-3",
          attrs: {
            "variant": "success",
            "block": ""
          },
          on: {
            "click": function ($event) {
              return _vm.submit();
            }
          }
        }, [_vm._v("確認送出訂課")]) : _vm._e()];
      }
    }])
  });

}
var staticRenderFns = []

export { render, staticRenderFns }